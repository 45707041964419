/* eslint-disable import/no-anonymous-default-export */
import React, { useCallback, useEffect, useState } from "react";
import { Navbar } from "../../components/Navbar";
import { MobileNavbar } from "../../components/MobileNavbar";
import { SmallHeroSection } from "../../components/Sections";
import { Footer } from "../../components";
import { Button, Col, Container, Row } from "react-bootstrap";

export default () => {

  const [img, setImg] = useState();

  const settingImg = useCallback(() => {
    if (window.innerWidth > 575.99) return "/images/book.jpg"
    return "/images/book-mobile.jpg"
  }, [])

  useEffect(() => {
    setImg(() => settingImg());
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => setImg(() => settingImg()))

    return window.removeEventListener('resize', () => setImg(() => settingImg()))
  }, [setImg, settingImg])

  return (
    <>
      <Navbar
        fixed
      />
      <MobileNavbar />
      <div>
        <SmallHeroSection
          img={img}
        />
        <Container>
          <Row
            style={{
              marginBottom: "100px"
            }}
            className="justify-content-center">
            <Col xs="12" sm="10" md="6">
              <p className="p-responsive"
                style={{ marginTop: "100px", textAlign: "center" }}
              >
                Przemierzając wioski i miasta w EwangeliBusie doświadczamy, że Miłość Boża objawia się cały czas w uzdrowieniach fizycznych i duchowych, w uwolnieniach oraz nawróceniach, na co mamy konkretne - niemożliwe świadectwa. Wiele z nich zostało opisane w książce, którą z całego serca Ci polecamy!
              </p>
              <br />
              <p className="text-center p-responsive">
                Dla sympatyków EwangeliBusa jest ona do nabycia w specjalnej ofercie u naszego partnera
                <div>
                  <Button
                    onClick={() => window.open('https://www.rtck.pl/sklep/ksiazki/niemozliwe-swiadectwa/?ref=87', '_blank')}
                    className={`mt-20px w-50 white-color border-color-yellow`}
                    style={{
                      color: "#0A0A0A",
                      backgroundColor: "#EDBD4C"
                    }}
                  >Przejdź do księgarni rtck</Button>
                </div>
              </p>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </>
  )
}