import React, { Suspense, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { Routes, Route, useLocation } from "react-router-dom";
import LoadingPage from './pages/LoadingPage/LoadingPage';
import "react-datepicker/dist/react-datepicker.css";
import { CookiesInformation, ScrollArrow } from './components';
import ReactBreakpoints from 'react-breakpoints/lib/ReactBreakpoints';
import withClearCache from './ClearCache';
import { BookView } from './pages/Book';
import ReactGA from 'react-ga4';

const BlogListView = React.lazy(() => import('./pages/BlogList/BlogListView'));
const BlogPostView = React.lazy(() => import('./pages/BlogPostView/BlogPostView'));
const HomeView = React.lazy(() => import('./pages/Home/HomeView'));
const OurMissionPage = React.lazy(() => import('./pages/OurMissionPage/OurMissionPage'));
const MapView = React.lazy(() => import('./pages/MapPage/MapPage'));
const GalleryView = React.lazy(() => import('./pages/Gallery/GalleryView'));
const IntentionsView = React.lazy(() => import('./pages/Intentions/IntentionsPage'));
const StatuteView = React.lazy(() => import('./pages/Statute/StatutePage'));
const PrivacyPolicyView = React.lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicyPage'));
const LukaszPlata = React.lazy(() => import('./pages/LukaszPlata/LukaszPlata'));
const PrayerFormView = React.lazy(() => import("./pages/ServiceForms/PrayerForm"));
const AdviceFormView = React.lazy(() => import("./pages/ServiceForms/AdviceForm"));
const ConferencesFormView = React.lazy(() => import("./pages/ServiceForms/ConferencesForm"));
const RetreatFormView = React.lazy(() => import("./pages/ServiceForms/RetreatForm"));

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  const breakpoints = {
    mobile: 575.99,
    mobileLandscape: 576,
    tablet: 768,
    tabletLandscape: 992,
    desktop: 1200,
    desktopLarge: 1400
  }
  const location = useLocation();
  ReactGA.initialize('G-P6YMFCML5L');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <ReactBreakpoints breakpoints={breakpoints}>
        <Suspense fallback={<LoadingPage />}>
          <Routes>
            <Route path="/" element={<HomeView />} />
            <Route path="relacje" element={<BlogListView />} />
            <Route path="trasa/:id" element={<BlogPostView />} />
            <Route path="nasza-misja" element={<OurMissionPage />} />
            <Route path="kalendarz" element={<MapView />} />
            <Route path="galeria" element={<GalleryView />} />
            <Route path="intencje" element={<IntentionsView />} />
            <Route path="regulamin" element={<StatuteView />} />
            <Route path="polityka-prywatnosci" element={<PrivacyPolicyView />} />
            <Route path="niemozliwe-swiadectwa" element={<BookView />} />
            <Route path="lukasz-plata" element={<LukaszPlata />} />
            <Route path="lukasz-plata/modlitwa-wstawiennicza" element={<PrayerFormView />} />
            <Route path="lukasz-plata/poradnictwo-duchowe" element={<AdviceFormView />} />
            <Route path="lukasz-plata/rekolekcje" element={<RetreatFormView />} />
            <Route path="lukasz-plata/konferencje-i-wyklady" element={<ConferencesFormView />} />
          </Routes>
        </Suspense>
        <CookiesInformation />
        <ScrollArrow />
      </ReactBreakpoints>
    </div>
  );
}

export default App;
