/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Container, Navbar, Row, Col, Offcanvas, Nav, NavDropdown } from "react-bootstrap";
import { navbarItems } from "../../utils/navbar";
import styles from "./MobileNavbar.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import scrollTo from "../../utils/scrollTo";

export default () => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  // const [hideNavbar, setHideNavbar] = useState(true);

  /*
  useEffect(() => {
    if (window.scrollY > 250) setHideNavbar(true);
    document.addEventListener('scroll', function (e) {
      if (window.scrollY > 250) {
        setHideNavbar(false);
      } else {
        setHideNavbar(true);
      }
    });
  }, [])
*/
  return (
    <>
      <Navbar
        expanded={showMenu}
        expand={false}
        className={`d-block d-sm-none ${styles.navbarContainerFixed}`}>
        <Container>
          <Row
            style={{
              paddingLeft: "20px",
              paddingRight: "20px"
            }}
          >
            <Col
              className={styles.colIcon}
              xs="4">
              <img
                style={{
                  width: "100%"
                }}
                src="/logo.jpg" />
            </Col>
            <Col xs="4"></Col>
            <Col
              className={`${styles.burgerIconCol}`}
              xs="4">
              <img
                onClick={() => setShowMenu(true)}
                style={{
                  width: "34px"
                }}
                src="/icons/hamburger.png" />
            </Col>
          </Row>
          <Navbar.Offcanvas
            id="offcanvas-mobile-menu"
            placement="end"
          >
            <Offcanvas.Header
              style={{
                justifyContent: "end"
              }}
              onHide={() => setShowMenu(false)}
              closeButton />
            <div
              className="mt-5 text-center"
            >
              <img
                style={{ width: "186px" }}
                src="/logo.jpg"
              />
              <h5 className="mt-5">MENU</h5>
            </div>
            <Offcanvas.Body
              className="text-center">
              <Nav
                className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link
                  onClick={() => {
                    setShowMenu(false)
                  }
                  }
                  as={NavLink}
                  to={"/"}
                  style={{
                    color: "black"
                  }}
                >
                  Strona główna
                </Nav.Link>
                {
                  navbarItems.map((e, index) => (
                    <Nav.Link
                      onClick={() => {
                        setShowMenu(false)
                      }
                      }
                      as={NavLink}
                      to={e.to}
                      key={`mobile-menu-item-${index}`}
                      style={{
                        color: "black"
                      }}
                    >
                      {e.label}
                    </Nav.Link>
                  ))
                }
                <NavDropdown
                  style={{
                    color: "black !important"
                  }}
                  title="Na skróty" id="nav-dropdown">
                  <NavDropdown.Item
                    as="div"
                    className="cursor-pointer"
                    onClick={() => scrollTo("contact")}>Kontakt</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => window.open('https://zrzutka.pl/tk7wpa', '_blank')}>Zatankuj EwangeliBusa</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => window.open('https://patronite.pl/EwangeliBus', '_blank')}>Zostań PATRONEM EwangeliBusa</NavDropdown.Item>
                  <NavDropdown.Item
                    className="cursor-pointer"
                    as="div"
                    onClick={() => scrollTo("team")}>Nasz zespół</NavDropdown.Item>
                  <NavDropdown.Item
                    className="cursor-pointer"
                    as="div"
                    onClick={() => navigate("/intencje")}>Skrzynka pytań i intencji</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      {/*
        <Offcanvas
          placement="end"
          id="s"
          show={showMenu} onHide={() => setShowMenu(false)}>
          <Offcanvas.Header
            style={{
              justifyContent: "end"
            }}
            closeButton />
          <div
            className="mt-5 text-center"
          >
            <img
              style={{ width: "186px" }}
              src="./logo.jpg"
            />
            <h5 className="mt-5">MENU</h5>
          </div>
          <Offcanvas.Body
            onClick={() => setShowMenu(false)}
            className="text-center">
            {
              navbarItems.map((e, index) => (
                <Nav.Link
                  key={`mobile-menu-item-${index}`}
                  style={{
                    color: "black"
                  }}
                  as={NavLink} to={e.to}>
                  {e.label}
                </Nav.Link>
              ))
            }
          </Offcanvas.Body>
        </Offcanvas>
      */}
    </>
  )
}