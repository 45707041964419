/* eslint-disable no-loop-func */
/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import styles from "./Pagination.module.css";

export default (props) => {
  const { totalPages, selectedPage, handleSelectedPage } = props;
  const [pages, setPages] = useState([]);

  const loop = () => {
    var result = [];
    for (var i = 1; i <= totalPages; i++) {
      result.push(
        i
      )
    }
    setPages(result);
  }

  useEffect(() => {
    loop()
  }, [totalPages])

  return (
    <div className="text-end">
      {pages.map((e, index) => (
        <span
          key={`page-number-${index}`}
          onClick={() => handleSelectedPage(e)}
          className={`${styles.item} p-responsive ${selectedPage ? styles.selectedItemColor : styles.defaultItemColor}`}
        >{e}</span>
      ))
      }
    </div >
  );
}