export const navbarItems = [
  {
    label: "Nasza misja",
    to: "/nasza-misja"
  },
  {
    label: "Kalendarz EwangeliBusa",
    to: "/kalendarz"
  },
  {
    label: "Relacje z wyjazdów",
    to: "/relacje"
  },
  {
    label: "Ks. Łukasz Plata",
    to: "/lukasz-plata"
  }
]