/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./Footer.module.css";
import scrollTo from "../../utils/scrollTo";
import { useNavigate } from "react-router-dom";

export default () => {
  const navigate = useNavigate();

  const sections = [
    {
      title: "Nasza misja",
      children: [
        {
          title: "Nasz zespół",
          action: () => scrollTo("team-contact")
        },/* {
          title: "Nasze cele"
        },*/ {
          title: "Ksiądz Łukasz",
          action: () => window.open("http://lukaszplata.pl/", '_blank')
        }
      ]
    },
    {
      title: "Nawigacja",
      children: [
        {
          title: "Nasza misja",
          action: () => navigate("/nasza-misja")
        }, {
          title: "Kalendarz EwangeliBusa",
          action: () => navigate("/kalendarz")
        }, {
          title: "Relacje z wyjazdów",
          action: () => navigate("/relacje")
        }
      ]
    },
    {
      title: "Pomoc",
      children: [
        {
          title: "Kontakt",
          action: () => scrollTo("team-contact")
        }, {
          title: "Zatankuj EwangeliBusa",
          action: () => window.open("https://zrzutka.pl/tk7wpa", '_blank')
        }, {
          title: "Regulamin",
          action: () => navigate("/regulamin")
        },
        {
          title: "Polityka prywatności",
          action: () => navigate("/polityka-prywatnosci")
        }
      ]
    },
    {
      title: "Social Media",
      children: [
        {
          title: "YouTube",
          action: () => window.open("https://www.youtube.com/c/%C5%81ukaszPlataEwangelizator", '_blank')
        }, {
          title: "Facebook",
          action: () => window.open("https://www.facebook.com/EwangeliBus", '_blank')
        }
      ]
    }
  ];

  return (
    <Container fluid className="backgroundColor-yellow pt-5">
      <Row>
        <Col
          xs="0"
          lg="2" />
        {
          sections.map((e, index) => (
            <Col
              key={`footer-section-${index}`}
              xs="12"
              sm="3"
              lg="2"
              className="text-center text-sm-start">
              <h5
                className={`${styles.title} mt-5 mt-sm-0`}
              >{e.title}</h5>
              {
                e.children.map((item, itemIndex) => (
                  <p
                    onClick={() => item.action()}
                    key={`footer-section-item-${index}-${itemIndex}`}
                    className={`cursor-pointer ${styles.item}`}>{item.title}</p>
                ))
              }
            </Col>
          ))
        }
        <Col
          xs="0"
          lg="2" />
      </Row>
      <Row>
        <Col className={styles.copyright}>&copy; 2022 EwangeliBus</Col>
      </Row>
    </Container>
  );
}