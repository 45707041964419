/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import { Col, Container, Navbar, Row, Nav, NavDropdown } from "react-bootstrap";
import scrollTo from "../../utils/scrollTo";
import styles from "./Navbar.module.css";
import { NavLink, useNavigate } from "react-router-dom";

export default ({ fixed }) => {
  const [hideNavbar, setHideNavbar] = useState(true);
  const navigate = useNavigate();
  const navbarItems = [
    {
      label: "Nasza misja",
      to: "/nasza-misja"
    },
    {
      label: "Kalendarz EwangeliBusa",
      to: "/kalendarz"
    },
    {
      label: "Relacje z wyjazdów",
      to: "/relacje"
    },
    {
      label: "Ks. Łukasz Plata",
      to: "/lukasz-plata"
    }
  ]

  useEffect(() => {
    if (!fixed) {
      if (window.scrollY > 250) setHideNavbar(true);
      document.addEventListener('scroll', function (e) {
        if (window.scrollY > 250) {
          setHideNavbar(false);
        } else {
          setHideNavbar(true);
        }
      });
    } else {
      setHideNavbar(false);
    }
  }, [])

  return (
    <Navbar
      className={`d-none d-sm-block position-fixed ${styles.mainNavbar}
       ${!fixed ? `${hideNavbar ? styles.hideNavbar : styles.showNavbar}`
          : styles.mainNavbarBase}`}>
      <Row>
        <Col xs="6" md="3">
          <Navbar.Brand
            as={NavLink}
            className={`${styles.navbarIconContainer}`}
            to="/">
            <img
              alt=""
              className={`${styles.navbarIcon}`}
              src="/logo.jpg" />
          </Navbar.Brand>
          <Navbar.Toggle />
        </Col>
        <Col xs="6" md="8">
          <Container
            style={{ textAlign: "center", height: "48px", alignItems: "center", display: "flex", justifyContent: "end" }}
          >
            {
              navbarItems.map((e, index) => (
                <Nav.Link
                  key={`navitem-${index}`}
                  className={styles.navbarItem}
                  as={NavLink} to={e.to}>
                  {e.label}
                </Nav.Link>
              ))
            }
            <NavDropdown
              className={styles.navbarItem}
              title="Na skróty" id="nav-dropdown">
              <NavDropdown.Item
                as="div"
                className="cursor-pointer"
                onClick={() => scrollTo("contact")}>Kontakt</NavDropdown.Item>
              <NavDropdown.Item onClick={() => window.open('https://zrzutka.pl/tk7wpa', '_blank')}>Zatankuj EwangeliBusa</NavDropdown.Item>
              <NavDropdown.Item onClick={() => window.open('https://patronite.pl/EwangeliBus', '_blank')}>Zosta PATRONEM EwangeliBusań</NavDropdown.Item>
              <NavDropdown.Item
                className="cursor-pointer"
                as="div"
                onClick={() => scrollTo("team")}>Nasz zespół</NavDropdown.Item>
              <NavDropdown.Item
                className="cursor-pointer"
                as="div"
                onClick={() => navigate("/intencje")}>Skrzynka pytań i intencji</NavDropdown.Item>
            </NavDropdown>
          </Container>
        </Col>
      </Row>
    </Navbar>
    /*
    <Container
      fluid
      className={`${classes} ${styles.navbarContainerFixed} pt-4`}
    >
      <Row>
        <Col

          xs="12"
          md="6"
        >
        </Col>
        <Col
          className={styles.navbarItems}
          xs="12"
          md="6"
        >
          {
            items.map((e, index) => (
              <div
                key={`navbar-item-${index}`}
                className={styles.navbarItem}>
                {e.label}
              </div>
            )
            )
          }
        </Col>
      </Row>
    </Container >
        */
  );
}