/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

export default () => {
  const [showButton, setShowButton] = useState(false);
  const topFunction = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  useEffect(() => {
    if (window.scrollY > 250) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
    document.addEventListener('scroll', function (e) {
      if (window.scrollY > 250) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, [])

  return (
    <Button
      onClick={() => topFunction()}
      style={{
        position: "fixed",
        bottom: "30px",
        right: "30px",
        height: "50px",
        width: "50px"
      }}
      className={`${!showButton && "d-none"}`}
      variant="success"
    >
      <img src="/icons/up-arrow.png"
        alt="up-arrow"
      />
    </Button>
  );
}