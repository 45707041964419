import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showMenu: false
};

export const counterSlice = createSlice({
  name: 'navbarMenu',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    showMenuAction: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.showMenu = true;
    },
    hideMenuAction: (state) => {
      state.showMenu = false;
    }
  }
});

export const { showMenuAction, hideMenuAction } = counterSlice.actions;

export const showMenu = (state) => state.navbarMenu.showMenu;

export default counterSlice.reducer;
