/* eslint-disable import/no-anonymous-default-export */
import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { Container, Row, Col, Form, Button, Alert, InputGroup, Spinner } from "react-bootstrap";
import styles from "./ContactSection.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { config } from "../../../helpers/config";
import ReCaptcha from "@matt-block/react-recaptcha-v2";

export default () => {
  const [formAlert, setFormAlert] = useState(null);
  const [sending, setSending] = useState(false);
  const [recaptcha, setRecaptcha] = useState("");
  const initialValues = { email: '', name: '', phone: '', content: '', recaptcha: "" };
  const formikRef = useRef(null);
  const item = (data) => (
    <Col
      className="mt-4" xs="12">
      <Row>
        <Col xs="5">
          <img
            alt="user"
            className={styles.avatar}
            src={data.img} />
        </Col>
        <Col
          className={styles.teamTeamData}
          xs="7">
          <Row>
            <Col
              className={styles.itemName}
              xs="12">
              {data.name}
            </Col>
            <Col
              className={styles.itemTitle}
              xs="12">
              {data.title}
            </Col>
            {data.showEmail &&
              <Col
                className={`${styles.itemEmail}`}
                xs="12">
                {data.email}
              </Col>
            }
          </Row>
        </Col>
      </Row>
    </Col>
  )
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => (
      <div
        className="mt-lg-0"
        style={{ position: "relative" }}
      >
        {dots}
      </div>
    ),
    customPaging: i => (
      <div
        className={`${styles.circle} backgroundColor-yellow`}
      >
        {i + 1}
      </div>
    )
  };

  const sendForm = (values) => {
    setSending(true);
    axios.post(`${config.apiUrl}contact/contactForm`, {
      ...values
    }).then(e => {
      setFormAlert({
        variant: "success",
        text: "Dzięki za kontakt. Doleciało!"
      })
      formikRef.current.resetForm();
      setSending(false);
    }).catch(e => {
      setFormAlert({
        variant: "danger",
        text: "Wystąpił nieoczekiwany błąd! Spróbuj ponownie lub napisz do nas na: kontakt@ewangelibus.pl"
      })
      setSending(false);
    })
  }

  return (
    <div
      id="team-contact"
      style={{ paddingTop: "80px", paddingBottom: "80px", marginBottom: "80px" }}
      className="backgroundColor-green"
    >
      <Container>
        <Row>
          <Col
            id="team"
            xs="12"
            lg="4"
          >
            <h1 className={`${styles.contactFormTitle} h1-responsive mt-5 mt-lg-0`}>Z Jezusem w drodze...</h1>
            <Slider {...settings}>
              <Row>
                {item({
                  img: "/images/ksiadz.png",
                  name: "Ksiądz Łukasz",
                  title: "kierowca EwangeliBusa",
                  email: "luk@wp.pl"
                })}
                {item({
                  img: "/images/jakub.jpg",
                  name: "Jakub",
                  title: "silnik EwangeliBusa",
                  email: "jakjus4@gmail.com"
                })}
                {item({
                  img: "/images/karolina.jpg",
                  name: "Karolina",
                  title: "okna EwangeliBusa",
                  email: "nitka.agnieszka18@gmail.com"
                })}
              </Row>
              <Row>
                {
                  item({
                    img: "/images/lawyer.jpg",
                    name: "Andrzej",
                    title: "Rejestracja EwangeliBusa(prawnik)"
                  })
                }
                {item({
                  img: "/images/agnieszka.jpg",
                  name: "Agnieszka",
                  title: "karoseria EwangeliBusa",
                  email: "nitka.agnieszka18@gmail.com"
                })}
                {item({
                  img: "/images/luke.jpg",
                  name: "Łukasz",
                  title: "rozrząd EwangeliBusa",
                  email: "lukasz.kosicki91@gmail.com"
                })}
              </Row>
              <Row>
                {
                  item({
                    img: "/images/siostra.jpg",
                    name: "Siostra Adrianna",
                    title: "carmedia"
                  })
                }
                {item({
                  img: "icons/new-user.jpg",
                  name: "Konrad z zespołem",
                  title: "serwis"
                })
                }
                {item({
                  img: "/images/mechanic.jpg",
                  name: "Michał",
                  title: "mechanik"
                })
                }
              </Row>
              <Row>
                {
                  item({
                    img: "/icons/new-user.jpg",
                    name: "A może Ciebie szukamy?",
                    showEmail: true,
                    title: "Napisz do nas!",
                    email: "kontakt@ewangelibus.pl"
                  })
                }
              </Row>
            </Slider>
          </Col>
          <Col
            id="contact"
            xs="12"
            lg="8"
          >
            <h1 className={`${styles.contactFormTitle} h1-responsive mt-5 mt-lg-0`}>Skontaktuj się z nami</h1>
            <Formik
              enableReinitialize
              innerRef={formikRef}
              initialValues={initialValues}
              validate={values => {
                const errors = {};
                //   if (!values.recaptcha) {
                //   errors.recaptcha = 'To pole jest obowiązkowe!';
                // }
                if (!values.email) {
                  errors.email = 'To pole jest obowiązkowe!';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Nieprawidłowy adres email!';
                }
                if (!values.name) errors.name = 'To pole jest obowiązkowe!'
                if (!values.phone) errors.phone = 'To pole jest obowiązkowe!'
                if (!values.content) errors.content = 'To pole jest obowiązkowe!'
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                if (recaptcha !== "") sendForm(values)
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                setFieldValue,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setValues,
                resetForm,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    {formAlert &&
                      <Col xs="12">
                        <Alert variant={formAlert.variant}
                          onClose={() => setFormAlert(null)} dismissible>
                          {formAlert.text}
                        </Alert>
                      </Col>
                    }
                    <Form.Group as={Col} xs="6" controlId="validationName">
                      <InputGroup
                        className="flex-column align-items-start"
                        hasValidation>
                        <Form.Control type="text" name="name"
                          className={styles.customFormControl}
                          onChange={handleChange}
                          placeholder="Imię*"
                          value={values.name}
                          isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback
                          className={styles.customFormControlFeedback}
                          type="invalid" tooltip>
                          {errors.name}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} xs="6" controlId="validationPhone">
                      <InputGroup
                        className="flex-column align-items-start"
                        hasValidation>
                        <Form.Control
                          className={styles.customFormControl}
                          name="phone"
                          onChange={handleChange}
                          isInvalid={!!errors.phone}
                          value={values.phone}
                          type="text" placeholder="Telefon" />
                        <Form.Control.Feedback
                          className={styles.customFormControlFeedback}
                          type="invalid" tooltip>
                          {errors.phone}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Form.Group as={Col} xs="12" controlId="validationEmail">
                      <InputGroup
                        className="flex-column align-items-start"
                        hasValidation>
                        <Form.Control
                          className={styles.customFormControl}
                          isInvalid={!!errors.email}
                          onChange={handleChange}
                          value={values.email}
                          type="email" name="email" placeholder="Email*" />
                        <Form.Control.Feedback
                          className={styles.customFormControlFeedback}
                          type="invalid" tooltip>
                          {errors.email}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Form.Group as={Col} xs="12" controlId="validationContent">
                      <InputGroup
                        className="flex-column align-items-start"
                        hasValidation>
                        <Form.Control
                          className={styles.customFormControl}
                          isInvalid={!!errors.content}
                          as="textarea"
                          name="content"
                          value={values.content}
                          onChange={handleChange}
                          placeholder="Wiadomość*"
                          style={{ height: '185px' }}
                        />
                        <Form.Control.Feedback
                          className={styles.customFormControlFeedback}
                          type="invalid" tooltip>
                          {errors.content}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  {false &&
                    <Row
                      style={{ marginTop: "20px" }}>
                      <Col
                        xs="12">
                        <Form.Check
                          className="d-flex align-items-center"
                          bsPrefix="contact-form-check-input"
                          type="checkbox"
                          id="accepted"
                        >
                          <Form.Check.Input
                            bsPrefix={styles.contactFormCheckInput}
                            type="checkbox" />
                          <Form.Check.Label
                            bsPrefix="contact-form-check-label"
                          >Akceptuję politykę prywatności*</Form.Check.Label>
                        </Form.Check>
                      </Col>
                    </Row>
                  }
                  <Row
                    className="align-items-center"
                    style={{ marginTop: "20px" }}>
                    <Col xs="12" md="8">
                      <ReCaptcha
                        size="normal"
                        onExpire={() => setRecaptcha("")}
                        siteKey="6LecayogAAAAAH69Da7x-c3Q0l3rtciWePjI9E_A" onSuccess={e => setRecaptcha(e)} />
                      {touched.recaptcha && errors.recaptcha &&
                        <Form.Control.Feedback
                          className={styles.customFormControlFeedback}
                          style={{
                            display: "inline"
                          }}
                          type="invalid" tooltip>
                          {errors.recaptcha}
                        </Form.Control.Feedback>
                      }
                    </Col>
                    <Col xs="12" md="4">
                      <Button
                        disabled={sending}
                        type="submit"
                        className={`${styles.btnSend} mt-3 mt-lg-0`}
                        style={{
                          color: "#0A0A0A",
                          backgroundColor: "#EDBD4C"
                        }}
                      >
                        {
                          sending ?
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            :
                            "Wyślij"
                        }
                      </Button>
                    </Col>
                  </Row>
                </form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </div >
  );
}