/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import CookieConsent from "react-cookie-consent";

export default () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Ok"
            cookieName="EwangeliBusCookiesInformation"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
        >
            Używamy plików cookies, aby ułatwić Ci korzystanie z naszego serwisu oraz do celów statystycznych.
            Korzystając z naszej strony wyrażasz zgodę na wykorzystywanie przez nas plików cookies.
            Jeśli nie blokujesz tych plików, to zgadzasz się na ich użycie oraz zapisanie w pamięci urządzenia.
            Pamiętaj, że możesz samodzielnie zarządzać cookies, zmieniając ustawienia przeglądarki.
        </CookieConsent>
    )
}