/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styles from "./LoadingPage.module.css";

export default () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{
        height: "100vh"
      }}
    >
      <img
        className={styles.preloader}
        style={{
          width: "200px"
        }}
        alt="logo"
        src="/logo.jpg"
      />
    </div>
  );
}