/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-undef */
import React, { useState, useCallback } from "react"
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { withBreakpoints } from 'react-breakpoints';

const MapTrip = (props) => {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);
  const { index, trip, type, breakpoints, currentBreakpoint } = props;

  return (
    breakpoints[currentBreakpoint] < breakpoints.desktop ? (
      < div
        className="cursor-pointer"
        key={`visited-trip-${index}`}
        style={{
          textAlign: "center",
          position: "absolute",
          left: `${trip.x}%`,
          top: `${trip.y}%`,
          transform: "translate(-50%, -25px)"
        }}
      >
        <OverlayTrigger
          trigger="click" placement="bottom" overlay={
            <Popover
              id="popover-basic">
              <Popover.Body>
                <p
                  style={{
                    color: type === "visited" ? "#86BD74" : "#EDBD4C",
                    marginBottom: "0px",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "25px",
                    letterSpacing: "0em"
                  }}>{trip.city}</p>
                <p
                  onClick={() => navigate(`/trasa/${trip.id}`)}
                  style={{
                    textAlign: "right",
                    color: type === "visited" ? "#86BD74" : "#EDBD4C",
                    marginBottom: "0px",
                    fontSize: "10px",
                    lineHeight: "25px",
                    letterSpacing: "0em"
                  }}>
                  Zobacz więcej
                </p>
              </Popover.Body>
            </Popover>
          }>
          <img
            className="marker"
            alt="marker"
            src={type === "visited" ? "/icons/visited.svg" : "/icons/planned.svg"}
          />
        </OverlayTrigger>
      </div >
    ) : (
      <div
        //    onMouseOver={() => setIsHovering(true)}
        //  onMouseOut={() => setIsHovering(false)}
        //  className="cursor-pointer"
        //  onClick={() => navigate(`/trasa/${trip.id}`)}
        key={`visited-trip-${index}`}
        style={{
          textAlign: "center",
          position: "absolute",
          left: `${trip.x}%`,
          top: `${trip.y}%`,
          transform: "translate(-50%, -25px)"
        }}
      >
        <img
          className="marker"
          alt="marker"
          src={type === "visited" ? "/icons/visited.svg" : "/icons/planned.svg"}
        />
        {false &&
          <p
            style={{
              color: type === "visited" ? "#86BD74" : "#EDBD4C",
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "25px",
              letterSpacing: "0em"
            }}>{trip.city}</p>
        }
      </div>
    ))
}

export default withBreakpoints(MapTrip)

