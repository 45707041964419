/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Container } from "react-bootstrap";
import styles from "./SmallHeroSection.module.css";

export default ({ img }) => {
  return (
    <Container
      /* style={{
         backgroundImage: `url(${img})`
       }} */
      className={styles.heroContainer}
      fluid>
      <img
        alt="hero"
        src={img}
        style={{
          width: "100%"
        }}
      />
    </Container>
  );
}